import dayjs from 'dayjs';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dpDateFormat',
  standalone: true,
})
export class DpDateFormatPipe implements PipeTransform {
  transform(value: dayjs.Dayjs | undefined, params: string) {
    return value ? value.format(params) : undefined;
  }
}
